<template>
  <div class="classify-page" :style="'background-image: url(' + classifyInfo.background + ')'">
    <div class="page-head">
      <div class="left">
        <div class="back" @click="back">
          <el-icon class="el-icon-back"></el-icon>
        </div>
        <div class="min-classify-list">
          <div :class="[activeMin === item.m_id ? 'active' : '', 'item']" v-for="item in minClassifyList" :key="item.id" @click="changeClassify(item.m_id)">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="right">
        {{this.classifyInfo.title}}----{{updatetime(nowDate)}}
      </div>
    </div>
    <div class="page-content">
      <div class="course-list">
        <div class="course-item" v-for="item in courseList" :key="item.id" @click="toCourse(item.m_id)">
          <img :src="item.thumbnail"/>
          <div class="title">{{item.title}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Classify',
  data () {
    return {
      classifyInfo: {
        m_id: '',
        tid: '',
        title: '',
        icon: '',
        background: ''
      },
      minClassifyList: [],
      activeMin: '',
      courseList: [],
      // 当前时间
      nowDate: new Date()
    }
  },
  created () {
    this.getClassifyInfo()
  },
  methods: {
    async getClassifyInfo () {
      const { data: res } = await this.$http.get('/classify', { params: { m_id: this.$route.params.classify_id } })
      if (res.code === 200) {
        this.classifyInfo.m_id = res.data.classifyInfo.m_id
        this.classifyInfo.tid = res.data.classifyInfo.tid
        this.classifyInfo.title = res.data.classifyInfo.title
        this.classifyInfo.icon = res.data.classifyInfo.icon
        this.classifyInfo.background = res.data.classifyInfo.background
        this.minClassifyList = res.data.minClassifyList
        if (this.minClassifyList.length > 0) {
          this.activeMin = this.minClassifyList[0].m_id
          const { data: res } = await this.$http.get('/course-list', { params: { m_id: this.minClassifyList[0].m_id } })
          if (res.code === 200) {
            this.courseList = res.data
          } else {
            this.$message.error(res.msg)
          }
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    // 根据课程小分类获取课程列表
    async getCouseList (mid) {
      const { data: res } = await this.$http.get('/course-list', { params: { m_id: mid } })
      if (res.code === 200) {
        this.courseList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 更改分类
    changeClassify (mid) {
      this.activeMin = mid
      this.getCouseList(mid)
    },
    updatetime (value) {
      return this.$moment(value).format('LTS')
    },
    toCourse (mid) {
      this.$router.push('/campus/' + this.$route.params.campus_id + '/special/' + this.$route.params.special_id + '/classify/' + this.$route.params.classify_id + '/course/' + mid)
    },
    back () {
      this.$router.push('/campus/' + this.$route.params.campus_id + '/special/' + this.$route.params.special_id)
    }
  },
  mounted () {
    const _this = this
    this.timer = setInterval(() => {
      _this.nowDate = new Date()
    }, 1000)
  },
  beforeDestroy () { // 生命周期实例销毁之前
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>

<style scoped>
.classify-page{
  width: 100%;
  height: 100vh;
  background-size: cover;
}
.page-head{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 60px;
}
.page-head .left{
  display: flex;
  align-items: center;
}
.page-head .left .back{
  width: 50px;
  height: 50px;
  background-color: #FBAE17;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: #FFFFFF;
  border-radius: 6px;
  cursor: pointer;
}
.page-head .left .min-classify-list{
  display: flex;
  align-items: center;
  margin-left: 14px;
}
.page-head .left .min-classify-list .item{
  background-color: #EBEEF5;
  padding: 4px 10px;
  border-radius: 30px;
  margin: 10px 10px 10px 0;
  cursor: pointer;
  font-size: 15px;
}
.page-head .left .min-classify-list .item.active{
  background-color: #FBAE17;
  color: #FFFFFF;
}
.course-list{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 40px;
}
.course-item{
  width: 180px;
  height: 120px;
  margin: 20px;
  cursor: pointer;
  position: relative;
}
.course-item:hover{
  transform: scale(1.05);
  transition: all .3s;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.course-item img{
  width: 100%;
  height: 120px;
  border-radius: 6px;
}
.course-item .title{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0,0,0,0.6);
  color: #FFFFFF;
  text-align: center;
  padding: 6px 8px;
  font-size: 14px;
  border-radius: 0 0 6px 6px;
}
.page-head .right {
  color: #FFFFFF;
  background-color: rgba(0,0,0,0.2);
  padding: 4px 10px;
  border-radius: 6px;
  font-size: 15px;
}
</style>
